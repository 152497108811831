.heart-rate {
  width: 150px;
  height: 73px;
  position: relative;
  margin: 20px auto;
  opacity: 1;
}

body {
  background-color: #eeeeee;
}

.loading-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 17%;
  right: -55%;
  animation: fade-in 1.5s linear infinite;
}

.fade-in {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  right: 0;
  animation: heartRateIn 1.5s linear infinite;
}

.fade-out {
  position: absolute;
  width: 120%;
  height: 100%;
  top: 0;
  left: 0;
  animation: heartRateOut 1.5s linear infinite;
  background: rgba(255, 255, 255, 1);
}

.heart-rate-global {
  width: 160px;
  height: 160px;
  position: relative;
  margin: 40px auto;
  opacity: 1;
}

.heart-circle-global {
  width: 160px;
  height: 160px;
  position: absolute;
  top: -24%;
  left: -3%;
  opacity: 1;
  animation: circleHeartLoopOuter 0s linear infinite;
}

.circle-heart-stroke-global {
  stroke: #8065a0;
  stroke-width: 4;
  animation: circleHeartLoop 1.5s linear infinite;
}

.fade-in-global {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  top: -20%;
  right: 0;
  animation: heartRateIn 1.5s linear infinite;
}

.fade-out-global {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -20%;
  left: 0;
  animation: heartRateOut 1.5s linear infinite;
  background: rgba(238, 238, 238, 1);
}

@keyframes heartRateIn {
  0% {
    width: 100%;
  }
  50% {
    width: 0;
  }
  100% {
    width: 0;
  }
}

@keyframes heartRateOut {
  0% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes circleHeartLoop {
  0% {
    stroke-width: 6;
    opacity: 0.7;
  }
  50% {
    stroke-width: 3;
    opacity: 0.2;
  }
  80% {
    stroke-width: 6;
    opacity: 0.7;
  }
  100% {
    stroke-width: 6;
    opacity: 0.7;
  }
}

@keyframes circleHeartLoopOuter {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
